import(/* webpackMode: "eager" */ "/home/item4/proj/growcastle.tools/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/growcastle.tools/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/growcastle.tools/node_modules/the-new-css-reset/css/reset.css");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/growcastle.tools/node_modules/@kfonts/bm-dohyeon/index.css");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/growcastle.tools/node_modules/@kfonts/nanum-square/index.css");
;
import(/* webpackMode: "eager" */ "/home/item4/proj/growcastle.tools/src/core/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/item4/proj/growcastle.tools/src/core/providers.tsx");
